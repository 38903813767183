<template>
  <div class="ma-9">
    <HnrButton
        :text="'Thêm mới combo'"
        color="primary"
        width="200px"
        @hnrClickEvent="addBook"
    />
    <v-data-table
        :headers="headers"
        :items="combos"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="getDetail(item.slug,item.id)">
          mdi-lead-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item.id)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:item.price="{ item }">
        {{item && item.price ? formatPrice(item.price) : 0}} Đ
      </template>
      <template v-slot:item.amount="{ item }">
        {{item && item.sale && item.amount ? formatPrice(item.amount) : 0}} Đ
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {
  GET_LIST_COMBO,DELETE_COMBO
} from "@/store/combo-course.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  components: {
    HnrButton: () => import("@/components/elements/hnr-button")
  },
  //list news
  data() {
    return {
      headers: [
        {text: "Tên combo", value: "title"},
        {text: "Slug", value: "slug"},
        {text: "Cấp độ", value: "level"},
        {text: "Giá", value: "price"},
        {text: "Giá", value: "amount"},
        {text: "Hành động", value: "actions", sortable: false}
      ]
    };
  },

  async created() {
    await this.$store.dispatch(GET_LIST_COMBO);
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "combo khoá học 1-1",titledad:'combo khoá học 1-1' }]);
  },

  computed: {
    ...mapGetters({
      combos: 'combos',
    }),
  },
  methods: {
    addBook() {
      this.$router.push('/combo-course/create')
    },
    getDetail(slug,id) {
      this.$router.push({
        path: `/combo-course/detail`,
        query:{
          slug : slug,
          id : id
        }
      })
    },
    async deleteItem(id) {
      if (confirm("Bạn muốn xóa deal sốc?")) {
        await this.$store.dispatch(DELETE_COMBO,{id : id} ) .then(data =>{
          if (data.data.status){
            this.$toasted.success('Xóa thành công', {
              position: "top-right",
              theme: "toasted-primary",
              duration : 1000
            });
            this.$store.dispatch(GET_LIST_COMBO);
          }
        });
      }
    },
    formatPrice(price){
      if (!price) return ''
      let data_price = parseFloat(price)
      return new Intl.NumberFormat('de-DE').format(data_price)
    },
  }
};
</script>